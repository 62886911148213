import { gql } from "@apollo/client";
import { useAvailableLanguages } from "@hooks/useAvailableLanguages";
import {
  UploadedTrainingResourceFragment,
  useBulkCreateTrainingResourcesMutation,
} from "@components/modals/bulkUploadTrainingResourceModal/BulkUploadTrainingResourceModal.generated";
import { UploadedFileList } from "@components/modals/bulkUploadTrainingResourceModal/UploadedFileList";
import UploadFilesDefaultState from "@components/modals/bulkUploadTrainingResourceModal/UploadFilesDefaultState";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Footer from "@src/deprecatedDesignSystem/components/Footer";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import Text from "@ui/text";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import TranslationBanner from "@src/deprecatedDesignSystem/components/TranslationBanner";
import { useFileListUpload } from "@hooks/useFileListUpload";
import { MediaFragment } from "@hooks/useFileUpload";
import { useModal } from "@hooks/useModal";
import { useToast } from "@hooks/useToast";
import {
  BulkCreateTrainingResourceInput,
  GraphqlOperations,
  TranslationSetInput,
} from "@src/types.generated";
import { launchConfetti } from "@utils/confetti";
import { pluralize, uuid4 } from "@utils/strings";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { updateTranslationSet } from "@utils/translationSets";
import { emptyTranslationSet } from "@utils/prompts";
import { useDropzone } from "react-dropzone";
import { useRouter } from "next/router";
import TrainingResourceBulkUploadConfirmationModal from "./TrainingResourceBulkUploadConfirmationModal";
import { ACCEPTED_FILE_TYPES_GROUPED } from "@src/utils/files";

export type FileInput = {
  id: string;
  name: TranslationSetInput;
  url?: string;
  size?: number;
  description?: TranslationSetInput;
};

type Props = {
  onFilesUploaded?: (files: UploadedTrainingResourceFragment[]) => void;
  setSelectedLibraryItemIdsFromBulkUpload?: (ids: string[]) => void;
  setSwitchingToResourceTabFromBulkUpload?: (value: boolean) => void;
};

const BulkUploadTrainingResourceModal: FC<Props> = (props) => {
  const { showModal, closeModal } = useModal();
  useEffect(() => {
    window.addEventListener("drop", (e) => e.preventDefault());
  }, []);
  const router = useRouter();
  const [files, setFiles] = useState<FileInput[]>([]);
  const updateFileName = useCallback(
    (id: string, lang: Language, name: string) => {
      setFiles((files) => {
        return files.map((file) => {
          if (file.id === id) {
            return {
              ...file,
              name: updateTranslationSet({
                ts: file.name,
                lang,
                text: name,
              }),
            };
          }
          return file;
        });
      });
    },
    [setFiles],
  );
  const updateFileDescription = useCallback(
    (id: string, language: Language, description: string) => {
      setFiles((files) => {
        return files.map((file) => {
          if (file.id === id) {
            return {
              ...file,
              description: updateTranslationSet({
                ts: file.description || emptyTranslationSet(),
                lang: language,
                text: description,
              }),
            };
          }
          return file;
        });
      });
    },
    [setFiles],
  );

  const removeFile = useCallback(
    (id: string) => {
      setFiles((files) => {
        return files.filter((file) => file.id !== id);
      });
    },
    [setFiles],
  );

  const [currentUploadBatch, setCurrentUploadBatch] = useState<FileInput[]>([]);
  const onUploadComplete = useCallback(
    (media: MediaFragment[]) => {
      const idToUrlInBatch = media.reduce(
        (acc: Record<string, string>, m, i) => {
          acc[currentUploadBatch[i].id] = m.url;
          return acc;
        },
        {},
      );
      setFiles((files) => {
        return files.map((file) => ({
          ...file,
          url: file.url ? file.url : idToUrlInBatch[file.id],
        }));
      });
    },
    [currentUploadBatch],
  );

  const [upload, { loading }] = useFileListUpload(onUploadComplete);
  const onFilesSelected = useCallback(
    (files: File[]) => {
      upload(files);
      const currentUploads = Array.from(files).map((file) => ({
        id: uuid4(),
        name: { en: file.name, translationOverrides: [] },
        size: file.size,
      }));
      setFiles((files) => [...files, ...currentUploads]);
      setCurrentUploadBatch(currentUploads);
    },
    [upload],
  );

  const onAddLink = useCallback(
    (url: string) => {
      setFiles([
        {
          id: uuid4(),
          name: { en: url, translationOverrides: [] },
          url: url,
        },
      ]);
    },
    [setFiles],
  );

  const { mostPopularNonEnglishLanguage } = useAvailableLanguages();
  const [translationLanguage, setTranslationLanguage] = useState(
    mostPopularNonEnglishLanguage,
  );
  const [translationModeEnabled, setTranslationModeEnabled] = useState(false);
  const inputs: BulkCreateTrainingResourceInput[] = useMemo(() => {
    return files.map((file) => ({
      mediaUrls: {
        en: file.url || "",
        translationOverrides: [],
      },
      name: file.name,
      description: file.description,
    }));
  }, [files]);
  const { addErrorToast } = useToast();
  const [createTrainingResources, { loading: mutationLoading }] =
    useBulkCreateTrainingResourcesMutation({
      variables: { inputs },
      onCompleted: (data) => {
        const uploadedFiles =
          data.bulkCreateTrainingResources.trainingResources;
        if (data.bulkCreateTrainingResources.success && uploadedFiles) {
          if (
            props.setSwitchingToResourceTabFromBulkUpload &&
            props.setSelectedLibraryItemIdsFromBulkUpload
          ) {
            router.push({
              pathname: router.pathname,
              query: { ...router.query, tab: "resources" },
            });
            props.setSwitchingToResourceTabFromBulkUpload(true);
            const libraryItemIds =
              uploadedFiles.map((r) => r.libraryItem.id) || [];
            props.setSelectedLibraryItemIdsFromBulkUpload(libraryItemIds);
          }
          closeModal();
          launchConfetti();
          if (
            props.setSwitchingToResourceTabFromBulkUpload &&
            props.setSelectedLibraryItemIdsFromBulkUpload
          ) {
            showModal(
              <TrainingResourceBulkUploadConfirmationModal
                numberOfResourcesUploaded={uploadedFiles.length}
              />,
            );
          }
          props.onFilesUploaded?.(uploadedFiles);
        } else {
          addErrorToast({ ...data, callsite: "bulk_upload_files" });
        }
      },
      refetchQueries: [
        GraphqlOperations.Query.ResourcesLibraryPaginatedTable_LibraryItems,
      ],
    });
  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      if (acceptedFiles.length !== 0) {
        onFilesSelected(acceptedFiles);
      }
    },
    [onFilesSelected],
  );
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    useFsAccessApi: true,
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: ACCEPTED_FILE_TYPES_GROUPED,
  });
  return (
    <Modal
      header={<TitleHeader title="Add Resources" onCancelClick={closeModal} />}
      footer={
        files.length > 0 ? (
          <Footer
            saveTitle={"Create Resources"}
            isSaveLoading={loading || mutationLoading}
            leftContent={
              <Text type="P2">
                {files.length} {pluralize("file", files.length)}
              </Text>
            }
            onSaveClick={() => {
              createTrainingResources();
            }}
            cancelTitle="Translate"
            onCancelClick={() => {
              if (!translationModeEnabled && !translationLanguage) {
                setTranslationLanguage(mostPopularNonEnglishLanguage);
              }
              setTranslationModeEnabled(!translationModeEnabled);
            }}
          />
        ) : undefined
      }
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} style={{ display: "none" }} />
        <AnimatePresence initial={false}>
          <motion.div
            animate={files.length === 0 ? "empty" : "full"}
            variants={{
              empty: {
                height: 370,
                transition: {
                  delay: 0,
                  duration: 0.3,
                },
              },
              full: {
                height: 500,
                transition: {
                  delay: 0,
                  duration: 0.3,
                },
              },
            }}
            style={{ backgroundColor: deprecatedTones.gray3Alpha }}
          >
            {files.length === 0 && (
              <UploadFilesDefaultState
                openFileSelector={open}
                onAddLink={onAddLink}
                isDragActive={isDragActive}
              />
            )}
            {files.length > 0 && (
              <motion.div
                animate={
                  translationModeEnabled
                    ? "translationModeEnabled"
                    : "translationModeDisabled"
                }
                variants={{
                  translationModeEnabled: {
                    width: 800,
                    transition: {
                      delay: 0,
                      duration: 0.3,
                    },
                  },
                  translationModeDisabled: {
                    width: 500,
                    transition: {
                      delay: 0,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div style={{ position: "sticky", top: 0, zIndex: 2 }}>
                  <TranslationBanner
                    translationModeEnabled={translationModeEnabled}
                    selectedLanguage={translationLanguage}
                    onLanguageChange={setTranslationLanguage}
                  />
                </div>
                <AutoLayout style={{ overflowY: "auto" }}>
                  <UploadedFileList
                    files={files}
                    updateFileName={updateFileName}
                    updateFileDescription={updateFileDescription}
                    removeFile={removeFile}
                    language={"en"}
                  />
                  {translationModeEnabled && translationLanguage && (
                    <UploadedFileList
                      files={files}
                      updateFileName={updateFileName}
                      updateFileDescription={updateFileDescription}
                      removeFile={removeFile}
                      language={translationLanguage}
                    />
                  )}
                </AutoLayout>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </Modal>
  );
};

gql`
  mutation BulkCreateTrainingResources(
    $inputs: [BulkCreateTrainingResourceInput!]!
  ) {
    bulkCreateTrainingResources(inputs: $inputs) {
      success
      trainingResources {
        ...UploadedTrainingResource
      }
    }
  }

  fragment UploadedTrainingResource on TrainingResource {
    id
    publishedVersion {
      id
      media {
        ...Media
      }
    }
    libraryItem {
      ...PathBuilderLibraryItem
    }
    ...TrainingResourceBuilder
  }
`;

export default BulkUploadTrainingResourceModal;
