import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { FC } from "react";

type Props = {
  tipCopy?: string;
};

const MultiSelectProTipBanner: FC<Props> = ({
  tipCopy = "Multi-select items in the library to grant access in bulk",
}) => {
  return (
    <AutoLayout
      direction="horizontal"
      backgroundColor={deprecatedTones.gray4Alpha}
      paddingVertical={8}
      paddingHorizontal={8}
      paddingRight={12}
      alignmentHorizontal="center"
      alignmentVertical="top"
      spaceBetweenItems={4}
      borderRadius={16}
      marginTop={16}
      marginBottom={12}
      style={{
        maxWidth: 475,
      }}
    >
      <DeprecatedIcon type="light-bulb" style={{ paddingBottom: 6 }} />
      <Text type="P2" fontWeight="Regular" multiline>
        <strong>Pro tip:</strong> {tipCopy}
      </Text>
    </AutoLayout>
  );
};

export default MultiSelectProTipBanner;
