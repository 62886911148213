import React, { FC, useCallback, useContext } from "react";
import { useModal } from "@src/hooks/useModal";
import AddLinkModal from "./AddLinkModal";
import { TiptapEditorContext } from "./contexts/TiptapEditorContext";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@src/ui/tooltip";
import TextBoldIcon from "@src/ui/icons/18px/text-bold";
import { Button } from "@src/ui/button";
import TextItalicIcon from "@src/ui/icons/18px/text-italic";
import OrderedListIcon from "@src/ui/icons/18px/ordered-list";
import UnorderedListIcon from "@src/ui/icons/18px/unordered-list";
import LinkIcon from "@src/ui/icons/18px/link";
import PhotoOutlineIcon from "@src/ui/icons/18px/photo-outline";
import { Separator } from "@src/ui/separator";
import MessageBlastTextStyleAction from "@src/components/libraryItemDetailPages/MessageBlastTextStyleAction";
import AddMediaModal from "@src/components/modals/addMedia/AddMediaModal";
import { MediaFragment } from "@src/fragments.generated";
import EmojiPicker from "../../ui/EmojiPicker";
import { Popover, PopoverContent, PopoverTrigger } from "@src/ui/popover";
import EmojiOutlineIcon from "@src/ui/icons/18px/emoji-outline";

type Props = {
  hideRichTextHeaderOptions?: boolean;
  hideImageUpload?: boolean;
  hideSeparators?: boolean;
  showEmojiPicker?: boolean;
  buttonStyle?: "outline" | "ghost";
};

const RichTextEditorFormattingToolbar: FC<Props> = ({
  hideRichTextHeaderOptions = false,
  hideImageUpload = false,
  hideSeparators = false,
  showEmojiPicker = false,
  buttonStyle = "ghost",
}) => {
  const { showModal } = useModal();
  const tiptapEditorContext = useContext(TiptapEditorContext);

  const handleImageUpload = (media: MediaFragment) => {
    if (!tiptapEditorContext?.editor) return;
    const imageUrl =
      media.unoptimizedUrl || media.imageUrls?.original || media.mediaUrls.en;
    if (!imageUrl) return;

    tiptapEditorContext.editor
      .chain()
      .focus()
      .setImage({ src: imageUrl })
      .run();
  };

  const onEmojiSelect = useCallback(
    (emoji: string) => {
      if (tiptapEditorContext?.editor) {
        const insertPoint =
          tiptapEditorContext.editor.view?.state?.selection?.anchor || 1;
        tiptapEditorContext.editor
          .chain()
          .focus()
          .insertContentAt(insertPoint, emoji, {
            parseOptions: {
              preserveWhitespace: false,
            },
          })
          .run();
      }
    },
    [tiptapEditorContext?.editor],
  );

  return (
    <TooltipProvider>
      <div className="flex flex-1 justify-between gap-1 self-stretch">
        {!hideRichTextHeaderOptions && <MessageBlastTextStyleAction />}
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                tiptapEditorContext?.formatting.isBold
                  ? "secondary"
                  : buttonStyle
              }
              size="icon"
              onClick={() => {
                if (!tiptapEditorContext?.editor) return;
                tiptapEditorContext.editor.chain().focus().toggleBold().run();
              }}
            >
              <TextBoldIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">Bold</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                tiptapEditorContext?.formatting.isItalic
                  ? "secondary"
                  : buttonStyle
              }
              size="icon"
              onClick={() => {
                tiptapEditorContext?.editor
                  ?.chain()
                  .focus()
                  .toggleItalic()
                  .run();
              }}
            >
              <TextItalicIcon />
            </Button>
          </TooltipTrigger>
          {!hideSeparators && (
            <div className="mx-1 py-1">
              <Separator orientation="vertical" />
            </div>
          )}
          <TooltipContent side="bottom">Italic</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                tiptapEditorContext?.formatting.isBulletList
                  ? "secondary"
                  : buttonStyle
              }
              size="icon"
              onClick={() => {
                tiptapEditorContext?.editor
                  ?.chain()
                  .focus()
                  .toggleBulletList()
                  .run();
              }}
            >
              <UnorderedListIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">Bulleted List</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                tiptapEditorContext?.formatting.isOrderedList
                  ? "secondary"
                  : buttonStyle
              }
              size="icon"
              onClick={() => {
                tiptapEditorContext?.editor
                  ?.chain()
                  .focus()
                  .toggleOrderedList()
                  .run();
              }}
            >
              <OrderedListIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">Numbered List</TooltipContent>
        </Tooltip>
        {!hideSeparators && (
          <div className="mx-1 py-1">
            <Separator orientation="vertical" />
          </div>
        )}
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={buttonStyle}
              size="icon"
              onClick={() => {
                const selection =
                  tiptapEditorContext?.editor?.view.state.selection;
                if (!selection) {
                  return;
                }
                const text =
                  tiptapEditorContext?.editor?.view.state.doc.textBetween(
                    selection?.from,
                    selection?.to,
                    " ",
                  );
                showModal(
                  <AddLinkModal
                    text={text}
                    onComplete={(text, link) => {
                      tiptapEditorContext?.editor
                        ?.chain()
                        .focus()
                        .setLink({ href: link, target: "_blank" })
                        .insertContentAt(
                          {
                            from: selection.from,
                            to: selection.to,
                          },
                          text,
                        )
                        .unsetMark("link")
                        .run();
                    }}
                  />,
                );
              }}
            >
              <LinkIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">Link</TooltipContent>
        </Tooltip>
        {!hideImageUpload && (
          <Tooltip>
            <TooltipTrigger asChild>
              <AddMediaModal
                onMediaChange={(media) => {
                  if (media) {
                    handleImageUpload(media);
                  }
                }}
                title="Add image"
                trigger={
                  <Button size="icon" variant={buttonStyle}>
                    <PhotoOutlineIcon />
                  </Button>
                }
              />
            </TooltipTrigger>
            <TooltipContent side="bottom">Add image</TooltipContent>
          </Tooltip>
        )}
        {showEmojiPicker && (
          <Popover>
            <Tooltip>
              <TooltipTrigger asChild>
                <PopoverTrigger asChild>
                  <Button size="icon" variant={buttonStyle}>
                    <EmojiOutlineIcon />
                  </Button>
                </PopoverTrigger>
              </TooltipTrigger>
              <TooltipContent side="bottom">Emoji</TooltipContent>
            </Tooltip>
            <PopoverContent
              side="right"
              align="start"
              className="z-[9999] w-auto p-0"
              sideOffset={5}
            >
              <EmojiPicker onEmojiSelect={onEmojiSelect} />
            </PopoverContent>
          </Popover>
        )}
      </div>
    </TooltipProvider>
  );
};

export default RichTextEditorFormattingToolbar;
